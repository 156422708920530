<template>
  <div  class="main-container">  
    <img src="../assets/wallet-bg.jpg" class="bg">

     <div id="loading" v-if="showLoading">
      <div class="content">
 
        <v-icon name="spinner" pulse scale="3" />

      </div>
    </div>


    <div class="header">
      <div class="d-flex" style="width:50%"> 
        <h6 class="mt-3 text-white">{{ init.profile.fullName }}</h6>
        <b-button class="btn sc-button m-md-2" @click="goToDefault">Go to CXT</b-button>
      </div>
    </div>

    <div class="d-flex text-center justify-content-center">
      <button class="btn cancel" :class="{ active: state == 'token'}" @click="changeState('token')">TOKEN</button>
      <button class="btn cancel" :class="{ active: state == 'nft'}" @click="changeState('nft')">NFT</button>
    </div>

    <div class="d-flex container-fluid body-container justify-content-center" v-if="state == 'token'">
      <b-card style="max-width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('token')">
        <v-icon name="plus" scale="3" class="plus my-auto"/>
      </b-card>
      <AssetCard v-for="asset in assetInfo" :key="asset.id" v-if="!noAsset && !asset.nft && asset.active" :asset="asset" @view="viewCard(asset)"/>
    </div>

    <div class="d-flex container-fluid body-container justify-content-center" v-if="state == 'nft'">
      <b-card style="max-width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('nft')">
        <v-icon name="plus" scale="3" class="plus my-auto"/>
      </b-card>
      <AssetCard v-for="asset in assetInfo" :key="asset.id" v-if="!noAsset && asset.nft && asset.active" :asset="asset" @view="viewCard(asset)"/>
    </div>


    <SelectOption v-if="noAsset" @close="closeSelect"/>
    <TokenContract v-if="ifAddingAsset" @close="closeContract" :isNft="isNft"/>
    <ViewCard v-if="showAsset" @close="closeAsset" :info="selectedAsset"/>
  </div>
</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral' 
  import SelectOption from '@/components/modal/SelectOption.vue'
  import TokenContract from '@/components/modal/TokenContract.vue'
  import ViewCard from '@/components/modal/ViewCard.vue'
  import AssetCard from '@/components/card/AssetCard.vue'
  import Pusher from 'pusher-js' // import Pusher

  export default {
    components: {
      SelectOption,
      AssetCard,
      TokenContract,
      ViewCard
    },
    data: function() {
      return  {
        state: 'token',
        showLoading: true, 
        noAsset: false,
        ifAddingAsset: false,
        showAsset: false,
        assetInfo: {},
        selectedAsset: {},
        isNft: false,
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
      goToDefault() {
        this.$router.push('/')
      },

      initSelect: function() {
        this.noAsset = true
      },

      initContract: function(type) {
        this.ifAddingAsset = true
        if(type == 'token') {
          this.isNft = false
        } else if(type == 'nft') {
          this.isNft = true
        }
      },

      closeSelect() {
        this.noAsset = false
      },

      closeContract() {
        this.ifAddingAsset = false
        this.noAsset = false
      },

      viewCard(selected) {
        this.showAsset = true
        this.selectedAsset = selected
        console.log(',')
      },

      closeAsset() {
        this.showAsset = false
      },

      checkSession: function() {
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => { 
            this.getProfile();
            setTimeout( () => {
              this.showLoading = false;
              this.getAssetInfo();
            },1000)
            console.log(response)
          })
          .catch((err) => {
            console.log(err)
            if(err.response.data.msgCode == '00001') {
              window.location.href = "/#/login";
            }  

            if(err.response.data.msgCode == '00020') {
              setTimeout( () => {
              window.location.href = "/#/setup-profile";
              },1000)
            }
          })
      },

      getProfile: function(){
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/profile/',
        };

        this.axios(options)
          .then((response) => {
            this.init.profile = response.data;
          })
      },
      
      getAssetInfo() {
          const options = {
                method: 'POST',
                url: '/cx/smartcontract',
                };
                this.axios(options)
                    .then((response) => {
                      if(response.data.length > 0) {
                          console.log('yes')
                          this.assetInfo = response.data
                          this.noAsset = false
                      } else {
                        console.log('no')
                        // this.initSelect()
                      }
                    })
                    .catch((response) => {
                      console.log(response)
                      // if(response.assetKey != "" && response.assetName == "") {
                      //   this.showSteps.one = false
                      //   this.showCheck.one = true
                      //   this.showSteps.two = false
                      //   this.showSteps.three = true
                      //   this.showCheck.two = true
                      //   this.isDisabled.three = false
                      // }
                    });
          },

          changeState(state) {
            this.state = state
            console.log(this.state)
          }
    },

    beforeMount(){
     this.checkSession();
    },

    mounted() {
    },

     created () {
      // ...
     // this.subscribe()
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  #loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; 
    color: #FFF;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    background: #141414;
  }
  #loading .content {
    display: inline-block;
    text-align: center;
  }
  #loading .content img {
    display: block;
    margin-bottom: 0.5em;
  }
  #loading .content .icon {
    font-size: 3em;
  }

  .fa-icon { 
      color: #FFF;
      fill: #fff;
  }

  .header { 
    background: #141414;
    padding: 2em;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: #FFF;
  }

  .body-container {
    padding:10px;
  }

  .card-body {
    border-color:gray;
    border-width:2px;
    border-style:dashed;
  }

  .plus {
    fill:gray;
  }

  .cancel {
	  color:orange;
	  background:none;
	  border-color:orange;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:15%;
  }

  .active {
	  color:white;
	  background:orange;
	  border-color:orange;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:15%;
  }

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
 
  }
  /*med*/
  @media(max-width: 63.9375em) {

    
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(min-width: 768px) {
    .content {
      padding: 0 3em;
    }

    .transaction-content {
      padding: 3em;
    }

  }

</style>



