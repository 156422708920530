<template>
	<div class="backdrop">
	  <div class="b-modal">
		<div class="modalBody">
			<div class="header text-center text-white">
				<h3>You don’t have any Registered token,</h3>
			</div>
			<div class="header text-center text-white">
				<p>Would you like to create now?</p>
			</div>
			<div class="text-center btn-wrapper">
				<button class="btn submit" @click="initContract">CREATE TOKEN</button>
				<button class="btn cancel mt-2" @click="closeModal">CANCEL</button>
			</div>
		</div>
	  </div>
	  <TokenContract v-if="showContract" @close="closeContract" />
	</div>
  </template>
  
  <script>
  import TokenContract from '@/components/modal/TokenContract.vue'
  export default {
	  components: {
		TokenContract,
	  },
	  data: function() {
		  return {
			  showContract: false,
		  }
	  },
  
	  computed: {
		
	  },
  
	  mounted() {
	   
	  },
  
	  watch: {
		  
	  },
  
	  methods: {
		  closeModal(){
			  this.$emit("close")
		  },

		  initContract() {
			  this.showContract = true
		  },

		  closeContract() {
			this.showContract = false
		  }

	  },
  
	  mounted() {
	  },
  }
  </script>
  
  <style scoped>
  .backdrop {
	  top: 0;
	  right:0;
	  position: fixed;
	  width: 100vw;
	  height: 100vh;
	  z-index: 1;
	  background: rgba(0, 0, 0, 0.6);
  }
  .b-modal {
	  width: 400px;
	  height: auto;
	  margin: 175px auto;
	  background: black;
	  border-radius: 20px;
	  border-color:black;
	  border-width:2px;
	  z-index: 2;
	  overflow-x: hidden !important;
	  color:black;
  }
  
  .modal-header {
	  background-color: black;
	  margin-right:0px;
	  font-size:25px;
	  display:flex;
	  justify-content:space-between;
	  border:none;
  }
  
  .modal-title{
	  margin: 0 auto;
	  color:black;
  }
  
  .modalBody {
	  background-color:black;
	  width:100%;
	  padding:50px;
	  border-radius: 10px;
  }
  
  .buttonWrapper {
	  width:100%;
  }
  
  .x {
	  cursor:pointer;
  }
  .submit {
	  color:white;
	  background:orange;
	  border-color:orange;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:100%;
  }
  
  .cancel {
	  color:orange;
	  background:none;
	  border-color:orange;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
	  width:100%;
  
  }

  
	@media screen and (max-width: 1100px) {
	  
	}

	 @media screen and (max-width: 800px) {
	  
	}
  
	  @media screen and (max-width: 600px) {
			
	  }
  </style>